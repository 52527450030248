






























import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import BaseLayout from '@/shared/components/BaseLayout.vue'
import PriceSettings, { PriceTypes } from '@/shared/components/PriceSettings.vue'
import Account from '@/api/models/Account'
import AccountSettings from '@/api/models/AccountSettings'
import AlertType from '@/api/models/AlertType'

@Component({
    components: {
        BaseLayout,
        PriceSettings
    }
})
export default class Prices extends Vue {
    @Getter('getCurrentAccount')
    account!: Account

    @Action('updateAccountSettings')
    updateAccountSettings!: (settings: AccountSettings) => Promise<void>

    private accountSettings!: AccountSettings
    private alert: AlertType = {
        alertType: 'error',
        alertMessage: ''
    }

    get settings (): AccountSettings {
        return this.account.settings ?? {} as AccountSettings
    }

    get priceTypes (): PriceTypes {
        return {
            oldPriceType: this.settings.oldPriceType ?? undefined,
            priceType: this.settings.priceType ?? undefined
        }
    }

    private async save (): Promise<void> {
        this.closeAlert()
        const settings = this.account.settings
        if (this.accountSettings && settings) {
            settings.updatePrice = this.accountSettings.updatePrice
            settings.priceType = this.accountSettings.priceType
            settings.oldPriceType = this.accountSettings.oldPriceType

            try {
                await this.updateAccountSettings(settings)
                this.showAlert('success', this.$t('response.successful').toString())
            } catch (err) {
                let message = ''
                if (
                    Array.isArray(err.response.data.details) &&
                    err.response.data.details.length > 0
                ) {
                    message = err.response.data.details[0]
                } else {
                    message = err.response.data.message ?? err
                }
                this.showAlert('error', message)
            }
        }
    }

    private updatePrices (settings: AccountSettings): void {
        this.accountSettings = settings
    }

    private showAlert (type: string, message: string): void {
        this.alert.alertType = type
        this.alert.alertMessage = message
    }

    private closeAlert (): void {
        this.alert.alertMessage = ''
    }
}
